import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import _Stack from '@mui/material/Stack';
import { RootState } from '@/stores/rootReducer';
import { resetState } from '@/stores/FlashMessageSlice';
import { FlashMessage } from '@/components/atoms/FlashMessage';
import { Header } from '@/components/organisms/Commons/Header';
import { Footer } from '@/components/organisms/Footer';

type Props = {
  children: React.ReactNode;
};

export const Layout: React.VFC<Props> = (props) => {
  const { type, text } = useSelector(
    (state: RootState) => state.flashMessageManager
  );
  const dispatch = useDispatch();

  return (
    <>
      <Header />
      <Stack
        bgcolor={'#F5F5F5'}
        margin={'auto'}
        padding={2}
        overflow={'auto'}
        sx={{ minHeight: 'calc(100vh - 152px)' }}
      >
        <FlashMessage
          visible={!!text}
          type={type}
          onClose={() => {
            dispatch(resetState());
          }}
          text={text}
        />
        {props.children}
      </Stack>
      <Footer />
    </>
  );
};

const Stack = styled(_Stack)`
  padding: 16px;
`;
