import { ErrorBoundary as Boundary, FallbackProps } from 'react-error-boundary';
import * as Sentry from '@sentry/nextjs';
import { styled } from '@mui/material/styles';
import { Base } from '@/components/atoms/Base';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';

export const ErrorBoundary: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => <Boundary FallbackComponent={ErrorFallback}>{children}</Boundary>;

const ErrorFallback: React.VFC<FallbackProps> = ({ error }) => {
  Sentry.captureException(error);

  return (
    <Content>
      <Inner>
        <Title>
          <Text size={'L'} weight={'bold'}>
            エラーが発生しました
          </Text>
        </Title>
        <Card>
          <Message>
            <Text>{error.message}</Text>
          </Message>
          <ButtonWrapper>
            <Button
              color="primary"
              onClick={() => {
                globalThis.window.location.href = '/';
              }}
            >
              TOPページへ戻る
            </Button>
          </ButtonWrapper>
        </Card>
      </Inner>
    </Content>
  );
};

const Content = styled('div')`
  margin: 0;
  min-height: 100%;
`;

const Inner = styled('div')`
  width: 1120px;
  min-height: 100%;
  margin: 0 auto;
  padding: 24px 0;
`;

const Title = styled('div')`
  margin-bottom: 16px;
`;

const Card = styled(Base)`
  min-height: 100px;
  padding: 16px;
  margin: 0 8px 16px 8px;
  background-color: #ffffff;
`;

const Message = styled('div')`
  margin-bottom: 16px;
`;

const ButtonWrapper = styled('div')`
  text-align: right;
`;
