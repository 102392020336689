import { createContext, useCallback, useState } from 'react';

export const Header = ['date', 'name', 'price'];

export type Row = {
  date: string;
  name: string;
  price: string;
};

export const transform: { [key: string]: string } = {
  日付: 'date',
  相場名: 'name',
  レート: 'price',
};

type TypeContext = {
  values: Row[];
  setValues: (values: Row[]) => void;
};

export const Context = createContext<TypeContext>({
  values: [],
  setValues: () => {},
});

export const useProductSelectionsMarketPricesUpload = (): TypeContext => {
  const [values, setLocalValues] = useState<Row[]>([]);

  const setValues = useCallback((vs: Row[]): void => {
    setLocalValues(vs.filter((v) => v));
  }, []);

  return {
    values,
    setValues,
  };
};
