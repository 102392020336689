import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type Props = {
  color?: string;
  size?: number;
};

export const Loader: React.FC<Props> = (props) => {
  const { color = 'black', size = 75 } = props;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress style={{ color }} size={size} />
    </Box>
  );
};
