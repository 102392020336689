import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react';
import Router from 'next/router';
import { AUTH0_CONFIG } from '../constants';

const auth0ProviderOptions: Auth0ProviderOptions = {
  ...AUTH0_CONFIG,
  cacheLocation: 'localstorage',
  // https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#3-protecting-a-route-in-a-nextjs-app-in-spa-mode
  onRedirectCallback: (appState: AppState) => {
    Router.replace(appState?.returnTo || '/');
  },
};

type Props = {
  children: React.ReactNode;
};

export const AuthnProvider: React.VFC<Props> = ({ children }) => (
  <Auth0Provider {...auth0ProviderOptions}>{children}</Auth0Provider>
);
