import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { useContext } from 'react';

import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { Loader } from '@/components/atoms/Loader';
import { AuthnContext } from '@/utilities/auth/AuthnUIProvider/AuthnContainer';
import fetch from 'cross-fetch';

type Props = {
  children?: React.ReactNode;
};

const AuthorizedApolloProvider: React.VFC<Props> = ({ children }) => {
  const { isLoading, accessToken, role } = useContext(AuthnContext);

  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    fetch,
  });

  // See: https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
  const splitLink = split(({ query }) => {
    const definition = getMainDefinition(query);
    return !(
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  }, httpLink);

  if (isLoading) {
    return <Loader />;
  }

  // See: https://www.apollographql.com/docs/react/networking/authentication/#header
  const authLink = setContext(async () => {
    const token = await accessToken;
    const HasuraRole = await role;
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Hasura-Role': HasuraRole,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}> {children} </ApolloProvider>;
};

export default AuthorizedApolloProvider;
