import { SearchForm } from '@/types/product';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  search: SearchForm;
  searchUsed: boolean;
};

export const initialState: State = {
  search: {
    id: '',
    name: '',
    elements: {},
    masterProductTypeId: null,
    advanced: null,
  },
  searchUsed: false,
};

const formSlice = createSlice({
  name: 'formManager',
  initialState,
  reducers: {
    setSearchForm: (state: State, action: PayloadAction<SearchForm>) => ({
      ...state,
      search: action.payload,
    }),
    setSearchUsed: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      searchUsed: action.payload,
    }),
  },
});

export const { setSearchForm, setSearchUsed } = formSlice.actions;
export default formSlice;
