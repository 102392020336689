import Typography, { TypographyProps } from '@mui/material/Typography';

type Props = {
  children: React.ReactNode;
  weight?: 'bold' | 'inherit';
  size?: 'S' | 'M' | 'L' | 'XXL';
  as?: 'p' | 'span';
} & TypographyProps;

export const Presenter: React.VFC<Props> = (props) => {
  const {
    children,
    weight = 'inherit',
    size = 'M',
    as = 'span',
    sx,
    ...typographyProps
  } = props;
  typographyProps.fontWeight = weight;

  switch (size) {
    case 'XXL':
      typographyProps.fontSize = '2rem';
      break;
    case 'L':
      typographyProps.fontSize = '18px';
      break;
    case 'S':
      typographyProps.fontSize = '14px';
      break;
    default:
      // M
      typographyProps.fontSize = '16px';
      break;
  }
  return (
    <Typography
      {...typographyProps}
      sx={{ margin: 'revert', ...sx }}
      component={as}
    >
      {children}
    </Typography>
  );
};
