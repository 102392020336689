import Button, { ButtonProps } from '@mui/material/Button';

type Props = {
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
} & Omit<ButtonProps, 'prefix'>;

export const Presenter: React.FC<Props> = (props) => {
  const {
    color = 'primary',
    size = 'large',
    variant = 'contained',
    prefix,
    suffix,
    children,
    ...rest
  } = props;

  return (
    <Button
      size={size}
      color={color}
      variant={variant}
      startIcon={prefix}
      endIcon={suffix}
      {...rest}
    >
      {children}
    </Button>
  );
};
