import { keyframes, styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

type Props = {
  visible: boolean;
  type: 'success' | 'info' | 'warning' | 'error';
  onClose: () => void;
  text: string;
  autoHideDuration?: number;
};

export const Presenter = (props: Props): JSX.Element => {
  const { visible, type, onClose, text, autoHideDuration = 6000 } = props;

  return (
    <FlashMessageWrapper
      open={visible}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert
        variant="outlined"
        severity={type}
        onClose={onClose}
        iconMapping={{
          success: <CheckCircleRoundedIcon fontSize="small" color="primary" />,
          info: <InfoIcon fontSize="small" color="info" />,
          warning: (
            <ReportProblemRoundedIcon fontSize="small" color="warning" />
          ),
          error: <ErrorIcon fontSize="small" color="error" />,
        }}
      >
        <AlertTitle>{text}</AlertTitle>
      </Alert>
    </FlashMessageWrapper>
  );
};

const bounceAnimation = keyframes`
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(-50%, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-50%, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-50%, -15px, 0);
  }
  90% {
    transform: translate3d(-50%, -4px, 0);
  }
`;

const FlashMessageWrapper = styled(Snackbar)`
  animation: ${bounceAnimation} 1s 0s both;
`;
