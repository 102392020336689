import { AccessTokenPayload } from '@/types/jwt';
import { AUTH0_CONFIG, NAME_SPACE, Role } from '@/utilities/auth/constants';
import { useAuth0, User } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { createContext, useCallback, useEffect, useState } from 'react';

type Context = {
  isLoading: boolean;
  accessToken: string;
  userId: string;
  tenantId: string;
  tenantName: string;
  isBuysellTenant: boolean;
  user?: User;
  role?: Role;
  roles?: string[];
  logout: () => void;
};

// 複数該当する role が存在する場合は左から優先される
export const ACCESS_ALLOWED_ROLES = [Role.MANAGER, Role.VIEWER];

export const AuthnContext = createContext<Context>({
  isLoading: true,
  accessToken: '',
  userId: '',
  tenantId: '',
  tenantName: '',
  isBuysellTenant: true,
  user: {},
  role: undefined,
  roles: [],
  logout: () => {},
});

const useAuth = () => {
  if (process.env.NODE_ENV === 'test') {
    return () => ({
      isLoading: false,
      user: {
        [NAME_SPACE]: {
          role_ids: ['promas_manager'],
          family_name: 'test',
          given_name: 'user',
        },
        name: 'testuser@test.com',
        nickname: 'tester',
        picture:
          'https://s.gravatar.com/avatar/dccbaaa33d64d92b148f28f37cb510ad?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fin.png',
      },
      getAccessTokenSilently: () => process.env.NEXT_PUBLIC_TEST_USER_JWT || '',
      isAuthenticated: true,
      loginWithRedirect: () => {},
      logout: () => {},
    });
  }
  return useAuth0;
};

type Props = {
  children: React.ReactNode;
};

export const AuthnContainer: React.VFC<Props> = ({ children }) => {
  const {
    isAuthenticated,
    isLoading: authenticatedLoading,
    getAccessTokenSilently,
    user,
    logout: logoutAuth0,
  } = useAuth()();
  const [tokenState, setTokenState] = useState({
    accessToken: '',
    userId: '',
    tenantId: '',
    tenantName: '',
    isLoading: true,
  });
  const isBuysellTenant =
    tokenState.tenantId === process.env.NEXT_PUBLIC_BUYSELL_TENANT_ID;
  const roles = user?.[NAME_SPACE]?.role_ids || [];
  const role = ACCESS_ALLOWED_ROLES.find((v) => roles.includes(v));
  const logout = useCallback(
    () => logoutAuth0({ client_id: AUTH0_CONFIG.clientId }),
    [logoutAuth0]
  );

  useEffect(() => {
    if (!isAuthenticated || tokenState.accessToken) return;
    (async () => {
      const token = await getAccessTokenSilently({
        audience: AUTH0_CONFIG.audience,
      });
      const decoded: AccessTokenPayload = jwtDecode(token);
      setTokenState({
        accessToken: token,
        userId: decoded[NAME_SPACE].user_id,
        tenantId: decoded[NAME_SPACE].tenant_id,
        tenantName: user?.[NAME_SPACE]?.tenant_name,
        isLoading: false,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 認証中はtrueを返し、認証失敗時はfalseとする
  // 認証に成功した場合は、getTokenのloading状態を返す
  const loading = !isAuthenticated
    ? authenticatedLoading || false
    : tokenState.isLoading;

  return (
    <AuthnContext.Provider
      value={{
        ...tokenState,
        isLoading: loading,
        isBuysellTenant,
        user,
        role,
        roles,
        logout,
      }}
    >
      {children}
    </AuthnContext.Provider>
  );
};
