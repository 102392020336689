import { useState } from 'react';
import NextLink from 'next/link';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { pagesPath } from '@/lib/$path';

import { Avatar } from '@/components/molecules/Avatar/index';
import { CheckManagerPermission } from '@/components/CheckManagerPermission';

export const Presenter: React.VFC = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <CheckManagerPermission
      render={(isPermission) => (
        <AppBar
          position="static"
          sx={{ color: 'white', backgroundColor: 'black' }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <svg width="152" height="27">
                {COSMOS_SVG_PATH}
                {PROMAS_SVG_PATH}
              </svg>
              <Typography sx={{ ml: 1, mr: 2 }}>{TENANT_NAME}</Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {isPermission ? (
                  <>
                    <StyledButton onClick={handleOpenNavMenu}>
                      <Box sx={{ display: { md: 'flex' } }}>
                        商品マスタ
                        <ArrowDropDownIcon />
                      </Box>
                    </StyledButton>
                    <Menu
                      sx={{ mt: '45px' }}
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                    >
                      <MenuItem>
                        <NextLink href={pagesPath.products.$url()} passHref>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            sx={{
                              textDecoration: 'none',
                              color: 'text.primary',
                            }}
                          >
                            商品マスタ一覧
                          </Link>
                        </NextLink>
                      </MenuItem>
                      <MenuItem>
                        <NextLink
                          href={pagesPath.products.transfers.$url()}
                          passHref
                        >
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            sx={{
                              textDecoration: 'none',
                              color: 'text.primary',
                            }}
                          >
                            商品マスタ振替
                          </Link>
                        </NextLink>
                      </MenuItem>
                      <MenuItem>
                        <NextLink
                          href={pagesPath.products.search.$url()}
                          passHref
                        >
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            sx={{
                              textDecoration: 'none',
                              color: 'text.primary',
                            }}
                          >
                            商品マスタ検索
                          </Link>
                        </NextLink>
                      </MenuItem>
                    </Menu>
                    <NextLink
                      href={pagesPath.products.marketPrices.$url()}
                      passHref
                    >
                      <StyledButton component="a">金性マスタ</StyledButton>
                    </NextLink>
                    <NextLink href={pagesPath.products.gross.$url()} passHref>
                      <StyledButton component="a">グロス価格一覧</StyledButton>
                    </NextLink>
                  </>
                ) : (
                  <NextLink href={pagesPath.products.search.$url()} passHref>
                    <StyledButton component="a">商品マスタ検索</StyledButton>
                  </NextLink>
                )}
              </Box>
              <Avatar />
            </Toolbar>
          </Container>
        </AppBar>
      )}
    />
  );
};

const StyledButton = styled(Button)`
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.75;
  display: block;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
` as typeof Button;

const TENANT_NAME = 'BuySell Technologies';

const COSMOS_SVG_PATH: ReadonlyArray<React.SVGProps<SVGPathElement>> = [
  <path
    d="M15.068,0.091
    c-0.113-0.013-0.227-0.024-0.34-0.035c-0.114-0.01-0.228-0.019-0.343-0.026c-0.057-0.004-0.115-0.007-0.172-0.01
    c-0.115-0.006-0.23-0.01-0.346-0.013c-0.116-0.003-0.232-0.004-0.348-0.004s-0.233,0.001-0.348,0.004
    c-0.116,0.003-0.231,0.007-0.346,0.013c-0.403,0.02-0.801,0.058-1.193,0.113C5.685,0.966,0.981,5.67,0.149,11.615
    c-0.055,0.393-0.093,0.791-0.113,1.193c-0.006,0.115-0.01,0.23-0.013,0.346c-0.003,0.116-0.004,0.232-0.004,0.348
    c0,0.233,0.006,0.465,0.018,0.695c0.006,0.115,0.013,0.23,0.022,0.344c0.017,0.229,0.04,0.455,0.069,0.681
    c0.021,0.169,0.046,0.337,0.074,0.504c0.009,0.056,0.019,0.111,0.029,0.167c0.049,0.277,0.107,0.551,0.174,0.822
    c0.013,0.054,0.027,0.108,0.041,0.162C1.941,22.7,7.227,27.003,13.518,27.003c7.456,0,13.5-6.044,13.5-13.5
    C27.018,6.571,21.794,0.86,15.068,0.091z M21.99,5.031c1.101,1.101,1.965,2.382,2.568,3.809c0.624,1.476,0.941,3.045,0.941,4.663
    s-0.317,3.187-0.941,4.663c-0.603,1.427-1.467,2.708-2.568,3.809c-0.678,0.678-1.425,1.266-2.233,1.759
    c0.485-0.821,0.622-1.73,0.396-2.685c-0.377-1.596-1.76-2.636-3.191-3.3c0.561-0.73,1.062-1.451,1.502-2.175
    c1.499-2.497,1.953-4.562,1.358-6.157c-0.128-0.364-0.311-0.703-0.549-1.017c-0.102-0.134-0.211-0.261-0.325-0.377
    C3.784,6.451,4.37,5.707,5.046,5.031C6.147,3.93,7.428,3.066,8.855,2.462c1.476-0.624,3.045-0.941,4.663-0.941
    c1.618,0,3.187,0.317,4.663,0.941C19.608,3.066,20.889,3.93,21.99,5.031z M5.551,15.354c1.04-1.019,2.023-1.943,2.974-2.768
    c0.022,0.083,0.044,0.167,0.065,0.252c0.242,0.993,0.423,2.09,0.551,3.353c-0.223-0.013-0.45-0.025-0.682-0.036
    C7.594,15.672,6.604,15.384,5.551,15.354z M12.202,9.834c1.279-0.767,2.407-1.218,3.353-1.341c0.814-0.106,1.622,0.09,2.162,0.525
    c0.889,0.715,0.838,1.926,0.775,2.413c-0.123,0.946-0.574,2.074-1.34,3.35c-0.482,0.792-1.046,1.585-1.688,2.396
    c-0.326-0.102-0.636-0.186-0.914-0.253c-1.127-0.274-2.39-0.476-3.858-0.614c-0.137-1.458-0.339-2.713-0.612-3.834
    c-0.071-0.291-0.159-0.616-0.267-0.958C10.622,10.876,11.414,10.314,12.202,9.834z M14.392,18.466
    c-0.801,0.922-1.695,1.876-2.678,2.881c-0.059-1.041-0.369-2.015-0.871-2.864c-0.01-0.212-0.021-0.419-0.033-0.624
    c1.273,0.128,2.376,0.31,3.373,0.553C14.254,18.43,14.323,18.448,14.392,18.466z M5.046,8.346c1.063-0.045,1.839,0.808,2.123,1.176
    c0.307,0.399,0.586,0.899,0.833,1.494c-1.534,1.291-3.131,2.83-4.888,4.599c-0.084,0.083-0.168,0.167-0.251,0.249
    c-0.141,0.06-0.28,0.125-0.416,0.195c-0.193,0.1-0.381,0.21-0.562,0.329c-0.232-0.937-0.349-1.903-0.349-2.887
    c0-0.154,0.003-0.307,0.009-0.46c0.016-0.068,0.032-0.135,0.048-0.202c0.362-1.447,0.84-2.563,1.422-3.319
    C3.559,8.816,4.318,8.377,5.046,8.346z M5.367,16.998c2.597,0,4.711,2.113,4.711,4.711c0,1.074-0.357,2.092-1.014,2.92
    c-0.07-0.028-0.14-0.057-0.209-0.086c-1.427-0.603-2.708-1.467-3.809-2.568c-1.101-1.101-1.965-2.382-2.568-3.809
    c-0.019-0.044-0.037-0.088-0.055-0.132C3.252,17.368,4.286,16.998,5.367,16.998z M11.333,23.905
    c0.012-0.012,0.023-0.023,0.035-0.035c1.769-1.757,3.307-3.354,4.598-4.886c0.614,0.252,1.128,0.536,1.536,0.85
    c0.369,0.284,1.221,1.059,1.176,2.123c-0.031,0.728-0.47,1.488-1.176,2.031c-0.756,0.582-1.873,1.06-3.317,1.422
    c-0.096,0.023-0.193,0.046-0.291,0.068c-0.126,0.004-0.251,0.006-0.378,0.006c-0.956,0-1.895-0.111-2.808-0.33
    C10.964,24.761,11.173,24.342,11.333,23.905z"
    key={1}
    style={{ fill: '#00C4CC' }}
  />,
];

const PROMAS_SVG_PATH: ReadonlyArray<React.SVGProps<SVGPathElement>> = [
  <path
    d="M50.664,24.471c-0.016-0.288-0.072-0.652-0.168-1.092c-0.096-0.44-0.2-0.772-0.312-0.996h1.848
		c0.319,0,0.552-0.061,0.696-0.181c0.144-0.12,0.216-0.331,0.216-0.636V11.559h-4.872v1.968c0,0.208,0.044,0.348,0.132,0.42
		s0.284,0.108,0.588,0.108h0.84c0.304,0,0.544-0.028,0.721-0.084c0.176-0.056,0.312-0.188,0.407-0.396
		c0.097-0.207,0.177-0.535,0.24-0.983c0.208,0.111,0.496,0.231,0.864,0.359c0.368,0.129,0.688,0.232,0.96,0.312
		c-0.096,0.752-0.252,1.324-0.468,1.716c-0.216,0.393-0.524,0.656-0.924,0.792c-0.4,0.136-0.937,0.204-1.608,0.204H48.12
		c-0.816,0-1.353-0.104-1.608-0.312c-0.256-0.207-0.384-0.543-0.384-1.008v-3.096h-1.824c-0.176,1.408-0.656,2.588-1.439,3.54
		c-0.784,0.952-2.064,1.732-3.84,2.34c-0.097-0.256-0.221-0.528-0.372-0.816c-0.152-0.287-0.3-0.527-0.444-0.72v8.521h-2.16V9.615
		h5.088c-0.176-0.416-0.368-0.836-0.575-1.26c-0.209-0.424-0.4-0.771-0.576-1.044l2.063-0.696h-7.031V4.743h9.432V2.535h2.256v2.208
		h9.432v1.872h-7.224l2.04,0.721c-0.16,0.319-0.356,0.687-0.588,1.104c-0.232,0.416-0.46,0.809-0.685,1.176h5.425v12.672
		c0,0.752-0.208,1.305-0.624,1.656c-0.417,0.352-1.057,0.528-1.92,0.528H50.664z M38.208,15.712c1.264-0.353,2.212-0.86,2.844-1.524
		s1.053-1.54,1.261-2.628h-4.104V15.712z M41.353,22.647v-5.928h8.496v5.928H41.353z M43.561,9.615h3.672
		c0.271-0.464,0.552-0.979,0.84-1.548c0.288-0.567,0.512-1.052,0.672-1.452H42.12c0.224,0.368,0.472,0.84,0.744,1.416
		C43.136,8.607,43.368,9.135,43.561,9.615z M43.344,20.991h4.488v-2.592h-4.488V20.991z"
    key={1}
    style={{ fill: 'white' }}
  />,
  <path
    d="M61.321,23.487v-9.264h8.735v9.264H61.321z M63.48,21.544h4.44v-5.328h-4.44V21.544z M65.448,12.135V3.639
		h11.544v8.496H65.448z M67.68,10.239h7.08v-4.68h-7.08V10.239z M72.432,23.487v-9.264h8.784v9.264H72.432z M74.568,21.544h4.513
		v-5.328h-4.513V21.544z"
    key={2}
    style={{ fill: 'white' }}
  />,
  <path
    d="M98.113,20.92c-0.384-0.528-0.849-1.097-1.393-1.704c-0.544-0.608-1.128-1.208-1.752-1.801
		c-0.624-0.592-1.252-1.144-1.884-1.655c-0.632-0.512-1.228-0.937-1.788-1.272l1.536-1.512c0.48,0.304,0.984,0.652,1.512,1.044
		c0.528,0.393,1.048,0.804,1.561,1.236c0.527-0.464,1.107-1,1.74-1.608c0.632-0.607,1.268-1.24,1.907-1.896
		c0.64-0.655,1.208-1.271,1.704-1.848c-0.863,0.017-1.856,0.044-2.976,0.084c-1.12,0.04-2.284,0.08-3.492,0.12
		s-2.368,0.084-3.48,0.132c-1.111,0.048-2.096,0.088-2.951,0.12c-0.856,0.032-1.484,0.064-1.885,0.096l-0.191-2.327
		c0.495,0.016,1.203,0.027,2.124,0.035c0.92,0.009,1.952,0.005,3.096-0.012c1.144-0.016,2.324-0.04,3.54-0.072
		c1.216-0.031,2.388-0.067,3.516-0.107c1.129-0.04,2.12-0.08,2.977-0.12c0.855-0.04,1.491-0.084,1.908-0.132l1.319,1.224
		c-0.368,0.513-0.836,1.104-1.403,1.776c-0.568,0.672-1.192,1.368-1.872,2.088c-0.681,0.72-1.36,1.416-2.04,2.088
		c-0.681,0.672-1.324,1.264-1.933,1.776c0.96,0.896,1.769,1.768,2.425,2.615c-0.145,0.097-0.337,0.249-0.576,0.456
		c-0.24,0.208-0.477,0.42-0.708,0.637C98.42,20.595,98.24,20.775,98.113,20.92z"
    key={3}
    style={{ fill: 'white' }}
  />,
  <path
    d="M108.264,21.951c-0.16-0.239-0.38-0.563-0.66-0.972s-0.54-0.708-0.779-0.9
		c1.231-0.527,2.463-1.199,3.695-2.016s2.408-1.731,3.528-2.748c1.12-1.016,2.136-2.096,3.048-3.24
		c0.912-1.144,1.664-2.3,2.256-3.468c-0.736,0.048-1.572,0.101-2.508,0.156s-1.88,0.116-2.832,0.18
		c-0.952,0.064-1.828,0.12-2.628,0.168c-0.801,0.048-1.433,0.088-1.896,0.12l-0.264-2.328c0.384,0,0.968-0.008,1.752-0.023
		c0.784-0.017,1.66-0.044,2.628-0.084s1.936-0.088,2.904-0.145c0.968-0.056,1.84-0.111,2.616-0.168
		c0.775-0.056,1.348-0.116,1.716-0.18l1.488,0.96c-0.4,1.104-0.908,2.184-1.524,3.24c-0.616,1.056-1.324,2.08-2.124,3.071
		c0.544,0.433,1.128,0.921,1.752,1.464c0.624,0.545,1.24,1.108,1.848,1.692c0.608,0.584,1.177,1.145,1.705,1.68
		c0.528,0.536,0.96,1.021,1.296,1.452c-0.271,0.177-0.596,0.429-0.972,0.756c-0.376,0.328-0.676,0.629-0.899,0.9
		c-0.321-0.448-0.725-0.948-1.213-1.5s-1.02-1.12-1.596-1.704s-1.152-1.147-1.728-1.692c-0.576-0.543-1.104-1.016-1.584-1.416
		c-1.328,1.425-2.776,2.717-4.345,3.876C111.376,20.244,109.816,21.199,108.264,21.951z"
    key={4}
    style={{ fill: 'white' }}
  />,
  <path
    d="M129.241,23.535c-0.128-0.304-0.324-0.66-0.588-1.067c-0.264-0.408-0.484-0.692-0.66-0.853
		c1.856-0.704,3.608-1.607,5.256-2.712c1.648-1.104,3.12-2.359,4.416-3.768c-0.527-0.368-1.056-0.712-1.584-1.032
		c-0.527-0.32-1.031-0.584-1.512-0.792l1.272-1.68c0.512,0.224,1.044,0.491,1.596,0.804c0.552,0.312,1.107,0.652,1.668,1.02
		c0.528-0.703,1.016-1.424,1.464-2.159c0.448-0.736,0.84-1.496,1.176-2.28c-0.512,0.016-1.08,0.04-1.704,0.072
		s-1.244,0.06-1.859,0.084c-0.616,0.023-1.177,0.044-1.681,0.06c-0.504,0.017-0.884,0.024-1.14,0.024
		c-0.72,1.104-1.54,2.151-2.46,3.144s-1.924,1.896-3.012,2.712c-0.112-0.144-0.269-0.319-0.469-0.528
		c-0.2-0.207-0.403-0.407-0.611-0.6s-0.384-0.328-0.528-0.408c1.136-0.8,2.2-1.739,3.192-2.819c0.991-1.08,1.855-2.236,2.592-3.469
		c0.735-1.231,1.296-2.464,1.68-3.695l2.112,0.672c-0.368,1.04-0.816,2.056-1.344,3.048c0.527-0.016,1.111-0.032,1.752-0.048
		c0.64-0.016,1.275-0.036,1.908-0.061c0.632-0.023,1.207-0.048,1.728-0.071c0.52-0.024,0.932-0.052,1.236-0.084l1.416,0.768
		c-0.433,1.28-0.957,2.492-1.572,3.636c-0.616,1.145-1.3,2.229-2.052,3.252c0.64,0.465,1.235,0.929,1.788,1.393
		c0.552,0.464,1.02,0.904,1.403,1.319c-0.144,0.097-0.324,0.261-0.54,0.492c-0.216,0.232-0.424,0.465-0.624,0.696
		c-0.2,0.232-0.348,0.428-0.443,0.588c-0.4-0.416-0.856-0.859-1.368-1.332c-0.513-0.472-1.064-0.932-1.656-1.38
		c-1.36,1.504-2.904,2.844-4.632,4.02C133.129,21.676,131.258,22.687,129.241,23.535z"
    key={5}
    style={{ fill: 'white' }}
  />,
];
