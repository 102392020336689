import { AuthnContext } from '@/utilities/auth/AuthnUIProvider/AuthnContainer';
import { Role } from '@/utilities/auth/constants';
import { useContext } from 'react';

type Props = {
  render: (isPermission: boolean) => React.ReactNode;
};

export const CheckManagerPermission: React.VFC<Props> = (props) => {
  const { role } = useContext(AuthnContext);
  return <>{props.render(role === Role.MANAGER)}</>;
};
