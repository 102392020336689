import Paper from '@mui/material/Paper';
import { SxProps } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
};

export const Presenter: React.VFC<Props> = (props) => {
  const { children, sx = {} } = props;

  return (
    <Paper sx={{ padding: '16px', marginTop: '16px', ...sx }}>{children}</Paper>
  );
};
