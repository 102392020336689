import { AuthnContainer } from './AuthnContainer';
import { PermissionCheck } from './PermissionCheck';

type Props = {
  children: React.ReactNode;
};

export const AuthnUIProvider: React.VFC<Props> = ({ children }) => (
  <>
    <AuthnContainer>
      <PermissionCheck>{children}</PermissionCheck>
    </AuthnContainer>
  </>
);
