import { useContext } from 'react';
import { LocalStorageCache } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { pagesPath } from '@/lib/$path';
import { AuthnContext } from '@/utilities/auth/AuthnUIProvider/AuthnContainer';
import { Role } from '@/utilities/auth/constants';

// promas_viewer の role を持つユーザーがアクセスできるページ
const PROMAS_VIEWER_ACCESS_ALLOWED_PAGES: string[] = [
  pagesPath.products.search.$url().pathname,
];

type Props = {
  children: React.ReactNode;
};

export const RolePermissionCheck: React.VFC<Props> = ({ children }) => {
  const router = useRouter();
  const { user, role } = useContext(AuthnContext);

  if (!user) {
    return <>{children}</>;
  }

  if (!role) {
    // LocalStorage に JWT が残っていると期限が切れるまでログインできなくなるので権限がない場合は削除する
    const storage = new LocalStorageCache();
    storage.allKeys().forEach((key) => storage.remove(key));
    globalThis.window.location.href = process.env
      .NEXT_PUBLIC_PORTAL_URL as string;
    return null;
  }

  const isAccessAllowedPage = PROMAS_VIEWER_ACCESS_ALLOWED_PAGES.includes(
    router.pathname
  );
  if (role === Role.VIEWER && !isAccessAllowedPage) {
    router.replace(pagesPath.products.search.$url().pathname);
    return null;
  }

  return <>{children}</>;
};
