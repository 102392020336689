import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Footer: React.VFC = () => (
  <StyledBox component="footer">
    <Typography
      align="right"
      component="span"
      sx={{ color: '#fff', flexGrow: 1 }}
    >
      © BuySell Technologies, Inc.
    </Typography>
  </StyledBox>
);

const StyledBox = styled(Box)`
  margin: 0;
  padding: 16px 24px;
  display: flex;
  background-color: #212121;
`;
