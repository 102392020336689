import Head from 'next/head';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { AppProps } from 'next/app';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import { Provider } from 'react-redux';
import { MuiTheme } from '@/styles/theme';
import GlobalStyle from '@/styles/global';
import store from '@/stores/store';
import AuthorizedApolloProvider from '@/utilities/apollo';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Layout } from '@/components/Layout';
import { SentryTagSetter } from '@/components/SentryTagSetter';
import { AuthnProvider } from '@/utilities/auth/AuthnProvider';
import { AuthnUIProvider } from '@/utilities/auth/AuthnUIProvider';
import {
  Context as ProductSelectionsMarketPricesUploadContext,
  useProductSelectionsMarketPricesUpload,
} from '@/contexts/productSelectionsMarketPrices/upload';

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  // NOTE: これはやめたほうがいいかも
  const SafeHydrate = dynamic(() => import('@/components/SafeHydrate'), {
    ssr: false,
  });
  const upload = useProductSelectionsMarketPricesUpload();

  return (
    <>
      <Head>
        <title>商品マスタ管理画面</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AuthnProvider>
        <SafeHydrate>
          <AuthnUIProvider>
            <Provider store={store}>
              <AuthorizedApolloProvider>
                <MuiThemeProvider theme={MuiTheme}>
                  <GlobalStyle />
                  <Layout>
                    <SentryTagSetter>
                      <ErrorBoundary>
                        <ProductSelectionsMarketPricesUploadContext.Provider
                          value={upload}
                        >
                          <Component {...pageProps} />
                        </ProductSelectionsMarketPricesUploadContext.Provider>
                      </ErrorBoundary>
                    </SentryTagSetter>
                  </Layout>
                </MuiThemeProvider>
              </AuthorizedApolloProvider>
            </Provider>
          </AuthnUIProvider>
        </SafeHydrate>
      </AuthnProvider>
    </>
  );
};

export default MyApp;
