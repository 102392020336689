import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { CsvType } from '@/types/price';

export type State = {
  fileData: CsvType[];
  masterProductTypeId?: number;
};
const initialState: State = {
  fileData: [],
};

const priceFileSlice = createSlice({
  name: 'fileManager',
  initialState,
  reducers: {
    setFileData: (state: State, action: PayloadAction<State>) => ({
      ...state,
      fileData: action.payload.fileData,
      masterProductTypeId: action.payload.masterProductTypeId,
    }),
    reset: (_state: State) => ({
      fileData: [],
      masterProductTypeId: undefined,
    }),
  },
});

export const { setFileData, reset } = priceFileSlice.actions;

export default priceFileSlice;
