import { useContext } from 'react';

import * as Sentry from '@sentry/nextjs';
import { AuthnContext } from '@/utilities/auth/AuthnUIProvider/AuthnContainer';

type Props = {
  children: React.ReactNode;
};

export const SentryTagSetter: React.VFC<Props> = (props) => {
  const { children } = props;
  const { userId, roles } = useContext(AuthnContext);

  // 以下でsentry送信時の情報をセット
  // See: https://buysell-tech.atlassian.net/wiki/spaces/PROM/pages/2128642216/Sentry
  Sentry.setUser({
    id: userId || 'not_login',
  });

  Sentry.setTag('allowed_roles', roles?.length ? roles.join(', ') : null);

  Sentry.setTag('service', 'promas-admin');

  return <>{children}</>;
};
