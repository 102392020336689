import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type State = {
  type: 'success' | 'info' | 'warning' | 'error';
  text: string;
};

const initialState: State = {
  type: 'info',
  text: '',
};

const flashMessageSlice = createSlice({
  name: 'flashMessageManager',
  initialState,
  reducers: {
    setState: (state: State, action: PayloadAction<State>) => ({
      ...state,
      type: action.payload.type,
      text: action.payload.text,
    }),
    resetState: (state: State) => ({
      ...state,
      type: initialState.type,
      text: initialState.text,
    }),
  },
});

export const { setState, resetState } = flashMessageSlice.actions;

export default flashMessageSlice;
