export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "products": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/products/[id]' as const, query: { id }, hash: url?.hash }),
      "transfer": {
        "create": {
          $url: (url?: { hash?: string }) => ({ pathname: '/products/[id]/transfer/create' as const, query: { id }, hash: url?.hash })
        }
      }
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/create' as const, hash: url?.hash })
    },
    "gross": {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/gross' as const, hash: url?.hash })
    },
    "image": {
      "upload": {
        $url: (url?: { hash?: string }) => ({ pathname: '/products/image/upload' as const, hash: url?.hash })
      }
    },
    "marketPrices": {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/marketPrices' as const, hash: url?.hash }),
      "confirm": {
        $url: (url?: { hash?: string }) => ({ pathname: '/products/marketPrices/confirm' as const, hash: url?.hash })
      }
    },
    "price": {
      "confirm": {
        $url: (url?: { hash?: string }) => ({ pathname: '/products/price/confirm' as const, hash: url?.hash })
      }
    },
    "search": {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/search' as const, hash: url?.hash })
    },
    "spec": {
      "confirm": {
        $url: (url?: { hash?: string }) => ({ pathname: '/products/spec/confirm' as const, hash: url?.hash })
      }
    },
    "specAndPrice": {
      "confirm": {
        $url: (url?: { hash?: string }) => ({ pathname: '/products/specAndPrice/confirm' as const, hash: url?.hash })
      }
    },
    "transfers": {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/transfers' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/products' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath

export const staticPath = {
  favicon_ico: '/favicon.ico',
  js: {
    newrelic_js: '/js/newrelic.js'
  },
  logo_service_name_svg: '/logo_service_name.svg'
} as const

export type StaticPath = typeof staticPath
