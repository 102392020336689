import { pagesPath } from '@/lib/$path';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { AuthnContext } from '@/utilities/auth/AuthnUIProvider/AuthnContainer';

// Buysell以外のテナントIDを持つユーザーがアクセスできるページ
const ACCESS_ALLOWED_PAGES = [
  pagesPath.$404.$url().pathname,
  pagesPath.$url().pathname,
  pagesPath.products.$url().pathname,
  pagesPath.products._id('').$url().pathname,
  pagesPath.products.transfers.$url().pathname,
  pagesPath.products._id('').transfer.create.$url().pathname,
  pagesPath.products.search.$url().pathname,
  pagesPath.products.marketPrices.$url().pathname,
  pagesPath.products.marketPrices.confirm.$url().pathname,
] as string[];

type Props = {
  children: React.ReactNode;
};

export const TenantPermissionCheck: React.VFC<Props> = ({ children }) => {
  const { isBuysellTenant, isLoading } = useContext(AuthnContext);
  const router = useRouter();
  const [isMounted, setIsMounted] = useState(false);

  // TODO: role管理に切り替え予定(暫定対応としてtenantIdにより閲覧ページを制限する)
  // https://buysell-tech.atlassian.net/browse/PROM-874
  useEffect(() => {
    setIsMounted(false);
    if (isLoading) return;
    const isAccessAllowedPage = ACCESS_ALLOWED_PAGES.includes(router.pathname);
    if (!isBuysellTenant && !isAccessAllowedPage) {
      router.replace(pagesPath.products.$url().pathname);
      // AuthProviderがReduxより上位層にあるためFlashMessageを呼び出せない
      // eslint-disable-next-line no-alert
      alert(
        `ページの閲覧権限がありません。\nURL: ${process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI}${router.asPath}`
      );
      return;
    }
    setIsMounted(true);
  }, [isBuysellTenant, router, isLoading]);

  if (!isMounted) return null;

  return <>{children}</>;
};
