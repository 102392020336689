import { Auth0ProviderOptions } from '@auth0/auth0-react';

export const NAME_SPACE = 'https://cosmos.api';

export const AUTH0_CONFIG: Pick<
  Auth0ProviderOptions,
  'clientId' | 'domain' | 'audience' | 'redirectUri'
> = {
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '',
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  redirectUri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
};

export const Role = {
  MANAGER: 'promas_manager',
  VIEWER: 'promas_viewer',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Role = (typeof Role)[keyof typeof Role];
