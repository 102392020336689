import * as Sentry from '@sentry/nextjs';

Sentry.init({
  // sentryにエラーを送る用のclient_key
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // performance monitoringでサンプルとして用いるtransactionの割合
  // いったん全てのtransactionを対象にするようにしている
  // See: https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/
  tracesSampleRate: 1.0,

  // Sentry にエラーを送る前に呼ばれる
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  beforeSend: (event, hint) => {
    const err = hint?.originalException;
    if (!err) return null;

    const errMsg = typeof err === 'string' ? err : err.message;
    if (errMsg.includes('ResizeObserver loop limit exceeded')) {
      return null;
    }

    return event;
  },
});
