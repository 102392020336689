import { RolePermissionCheck } from './RolePermissionCheck';
import { TenantPermissionCheck } from './TenantPermissionCheck';

type Props = {
  children: React.ReactNode;
};

// コンポーネント順は順不同
export const PermissionCheck: React.VFC<Props> = ({ children }) => (
  <>
    <RolePermissionCheck>
      <TenantPermissionCheck>{children}</TenantPermissionCheck>
    </RolePermissionCheck>
  </>
);
