import { createTheme as createMuiTheme } from '@mui/material/styles';

// https://github.com/buysell-technologies/buysell-ui/blob/5ea4c64d451ccba080250aaba36f6e79f3a8fd9c/src/themes/createColor.ts#L35-L53
const baseColor = {
  TEXT_BLACK: '#23221f',
  TEXT_GREY: '#706d65',
  TEXT_DISABLED: '#c1bdb7',
  TEXT_LINK: '#0071c1',
  BORDER: '#d6d3d0',
  ACTION_BACKGROUND: '#d6d3d0',
  BACKGROUND: '#f8f7f6',
  COLUMN: '#f8f7f6',
  OVER_BACKGROUND: '#f2f1f0',
  HEAD: '#edebe8',
  BASE_GREY: '#f5f4f3',
  MAIN: '#0077c7',
  DANGER: '#e01e5a',
  WARNING: '#ff8800',
  SCRIM: 'rgba(0,0,0,0.5)',
  OVERLAY: 'rgba(0,0,0,0.15)',
  BRAND: '#000000',
} as const;

// MUI を buysell-ui のデザインに合わせるためにカスタマイズ
export const MuiTheme = createMuiTheme({
  palette: {
    mode: 'light',
    primary: {
      main: baseColor.MAIN,
      contrastText: '#fff',
      700: '#0068ae',
    },
    secondary: {
      main: '#fff',
      contrastText: baseColor.TEXT_BLACK,
      A700: '#f2f2f2',
    },
    info: {
      main: baseColor.TEXT_GREY,
      contrastText: '#fff',
    },
    error: {
      main: baseColor.DANGER,
      contrastText: '#fff',
    },
    warning: {
      main: baseColor.WARNING,
      contrastText: '#fff',
    },
    grey: {
      500: baseColor.TEXT_GREY,
    },
    divider: baseColor.BORDER,
    text: {
      primary: baseColor.TEXT_BLACK,
      disabled: baseColor.TEXT_DISABLED,
    },
    action: {
      disabled: baseColor.ACTION_BACKGROUND,
    },
    background: {
      default: baseColor.BACKGROUND,
      paper: '#fff',
    },
  },
  typography: {
    fontFamily: '',
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover, &:active': {
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            pointerEvents: 'inherit',
            cursor: 'not-allowed',
          },
        },
        sizeLarge: {
          fontSize: '16px',
        },
        sizeMedium: {
          fontSize: '15px',
        },
        sizeSmall: {
          fontSize: '14px',
        },
        contained: {
          padding: '5px 16px',
          fontWeight: 'bold',
        },
        containedPrimary: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0,119,199,0.5)',
            color: 'rgba(255,255,255,0.5)',
          },
        },
        containedSecondary: {
          border: `1px solid ${baseColor.BORDER}`,
          '.MuiTouchRipple-child': {
            backgroundColor: 'rgba(0,0,0,0.3)',
          },
          '&.Mui-disabled': {
            backgroundColor: baseColor.BACKGROUND,
            color: baseColor.TEXT_DISABLED,
          },
        },
        containedError: {
          '&:hover': {
            backgroundColor: '#ca1b51',
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(224,30,90,0.5)',
            color: 'rgba(255,255,255,0.5)',
          },
        },
        textPrimary: {
          color: baseColor.TEXT_LINK,
          lineHeight: 'normal',
          border: '1px solid transparent',
          '&:hover, &:focus': {
            backgroundColor: '#f2f2f2',
          },
          '.MuiTouchRipple-child': {
            backgroundColor: 'rgba(0,0,0,0.3)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: baseColor.BORDER,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: baseColor.TEXT_BLACK,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: baseColor.BORDER,
          },
          '&.Mui-disabled': {
            backgroundColor: baseColor.BACKGROUND,
          },
        },
        input: {
          padding: '8px',
          color: baseColor.TEXT_BLACK,
          lineHeight: 1.6,
          '&::placeholder': {
            color: baseColor.TEXT_GREY,
            opacity: 1,
          },
          '&.Mui-disabled': {
            color: baseColor.TEXT_DISABLED,
            '-webkit-text-fill-color': baseColor.TEXT_DISABLED,
            opacity: 1,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '2.5px 4px 2.5px 2px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px', // shape の上書きを戻す
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: '8px 16px',
          height: '2.5rem',
          boxSizing: 'border-box',
          borderBottom: 'none',
          backgroundColor: baseColor.HEAD,
          color: baseColor.TEXT_BLACK,
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
        body: {
          borderTop: `1px solid ${baseColor.BORDER}`,
          borderBottom: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlined: {
          padding: '0 16px',
          color: baseColor.TEXT_BLACK,
          backgroundColor: '#fff',
          border: `1px solid ${baseColor.BORDER}`,
          borderRadius: '6px',
          boxShadow: '0 8px 16px 4px rgba(0,0,0,0.24)',
          alignItems: 'center',

          '.MuiTypography-root': {
            margin: 0,
          },
        },
        action: {
          padding: '0 0 0 16px',
        },
        icon: {
          marginRight: '8px',
        },
      },
    },
  },
});
