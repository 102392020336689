import { combineReducers } from '@reduxjs/toolkit';
import specFileSlice from './SpecFileSlice';
import priceFileSlice from './PriceFileSlice';
import specAndPriceFileSlice from './SpecAndPriceFileSlice';
import flashMessageSlice from './FlashMessageSlice';
import formSlice from './FormSlice';
import sortOrderSlice from './SortOrderSlice';

const rootReducer = combineReducers({
  specFileManager: specFileSlice.reducer,
  priceFileManager: priceFileSlice.reducer,
  specAndPriceFileManager: specAndPriceFileSlice.reducer,
  flashMessageManager: flashMessageSlice.reducer,
  formManager: formSlice.reducer,
  sortOrderManager: sortOrderSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
