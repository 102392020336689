import { useContext, useState } from 'react';

import { AuthnContext } from '@/utilities/auth/AuthnUIProvider/AuthnContainer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { NAME_SPACE } from '@/utilities/auth/constants';

export const Presenter: React.VFC = () => {
  const { logout, user } = useContext(AuthnContext);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const familyName = user?.family_name || user?.[NAME_SPACE]?.family_name;
  const givenName = user?.given_name || user?.[NAME_SPACE]?.given_name;

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <Button
          onClick={handleOpenUserMenu}
          sx={{
            p: 0,
            color: 'white',
            fontSize: '0.875rem',
            ':hover, :active, :focus': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {familyName}
          {givenName}
          <ArrowDropDownIcon />
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Typography textAlign="center" sx={{ mx: 2, my: 1, color: 'grey' }}>
          {user?.email}
        </Typography>
        <MenuItem
          key="logout"
          onClick={logout}
          sx={{ borderTop: 1, borderColor: 'grey.400' }}
        >
          <PowerSettingsNewIcon />
          <Typography textAlign="center">ログアウト</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
